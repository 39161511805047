export const photos = [
  {
    src: require("assets/img/rest_logos/stoa_logo.jpg"),
    width: 1,
    height: 1,
    site: "https://stoa60blog.wordpress.com/",
  },
  {
    src: require("assets/img/rest_logos/isogeio.jpg"),
    width: 1,
    height: 1,
    site: "https://isogeio.blackblogs.org/",
  },
  {
    src: require("assets/img/rest_logos/tiganitis_logo.jpg"),
    width: 1,
    height: 1,
    site: "https://tiganitis2015.wordpress.com",
  },
  {
    src: require("assets/img/rest_logos/ccc.jpg"),
    width: 1,
    height: 1,
    site: "https://www.facebook.com/cretan.climbing.community",
  },
  {
    src: require("assets/img/rest_logos/da_logo.jpg"),
    width: 1,
    height: 1,
    site: "https://www.facebook.com/da.heraklion/",
  },
  {
    src: require("assets/img/rest_logos/mopk_logo.png"),
    width: 1,
    height: 1,
    site: "https://www.facebook.com/music.uoc",
  },
  {
    src: require("assets/img/rest_logos/tolabaki_logo.png"),
    width: 1,
    height: 1,
    site: "https://wiki.tolabaki.gr/w/To_LABaki",
  },
]
