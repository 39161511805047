import React, { Component } from "react"
import IndexFooter from "../components/Footers/IndexFooter"
import { get_default_meta } from "../default_meta"
import DocumentMeta from "react-document-meta"
import SubsectionsGrid from "../components/Grids/SubsectionsGrid"

// const default_cover =
//   "https://images.unsplash.com/photo-1534531173927-aeb928d54385?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"

const subsections = [
  {
    title: "Shows",
    cover: require("assets/img/covers/shows.png"),
    short_description: "Currently running shows",
    page: "/shows",
  },
  {
    title: "Autopilot",
    cover: require("assets/img/covers/autopilot-01.png"),
    short_description: "Schedule",
    page: "/schedule_autopilot",
  },
  {
    title: "Archive",
    cover: require("assets/img/covers/archive.png"),
    short_description: "(under construction)",
    page: "/schedule_autopilot",
  },
  {
    title: "Wiki",
    cover: require("assets/img/covers/rastawiki.png"),
    short_description: "Details about the project",
    page: "https://gitlab.com/rastapank/public/-/wikis/home",
  },
  {
    title: "Streams",
    cover: require("assets/img/covers/icecast.png"),
    short_description: "Alternative streams",
    page: "/streams",
  },
]

class Radio extends Component {
  componentDidMount() {
    dispatchEvent(new Event("load"))
  }

  render() {
    return (
      <DocumentMeta {...get_default_meta({ title: "Ραδιόφωνο" })}>
        {window.dispatchEvent(new CustomEvent("new_page"))}
        <div className="main">
          <div className="section text-center">
            <SubsectionsGrid header="Ραδιόφωνο" subsections={subsections} />
          </div>
        </div>
        <IndexFooter />
      </DocumentMeta>
    )
  }
}

export default Radio
