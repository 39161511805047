export const photos = [
  {
    src: require("assets/img/acts/science_cooperation.jpg"),
    width: 1.33,
    height: 1,
  },
  {
    src: require("assets/img/acts/stop_wars.jpg"),
    width: 1,
    height: 1.33,
  },
  {
    src: require("assets/img/acts/toilo.jpg"),
    width: 2,
    height: 1,
  },
  {
    src: require("assets/img/acts/graduation.jpg"),
    width: 1.22,
    height: 1,
  },
  {
    src: require("assets/img/acts/snake.jpg"),
    width: 1,
    height: 2.05,
  },
  {
    src: require("assets/img/acts/nina.jpg"),
    width: 1.28,
    height: 1,
  },
  {
    src: require("assets/img/acts/mushroom.jpg"),
    width: 1.33,
    height: 1,
  },
  {
    src: require("assets/img/acts/louis.jpg"),
    width: 1.26,
    height: 1,
  },
  {
    src: require("assets/img/acts/church.jpg"),
    width: 1.32,
    height: 1,
  },
]
