import React, { Component } from "react"
import IndexFooter from "../components/Footers/IndexFooter"
import { get_default_meta } from "../default_meta"
import DocumentMeta from "react-document-meta"
import SubsectionsGrid from "../components/Grids/SubsectionsGrid"

// const default_cover =
//   "https://images.unsplash.com/photo-1534531173927-aeb928d54385?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"

const subsections = [
  {
    title: "Blog",
    cover: require("assets/img/covers/παρεμβάσεις_cover.jpg"),
    //short_description: 'Εκθέσεις, φεστιβάλ και λοιπά καλλιτεχνικά δρώμενα',
    page: "/acts",
  },
  {
    title: "Posters",
    cover: require("assets/img/covers/events_cover.jpg"),
    //short_description: 'Εκθέσεις, φεστιβάλ και λοιπά καλλιτεχνικά δρώμενα',
    page: "/events",
  },
  {
    title: "Gallery",
    cover: require("assets/img/covers/events_cover.jpg"),
    short_description: 'Under Construction',
    page: "/gallery",
  },
  {
    title: "Φουκού Βούτες",
    cover: require("assets/img/covers/foukou.jpg"),
    short_description: 'Home',
    page: "https://gitlab.com/foukou/space/-/wikis/home",
  },
  {
    title: "Μακροβούτες Festival",
    cover: require("assets/img/covers/μακροβούτες_cover.png"),
    //short_description: 'Εκθέσεις, φεστιβάλ και λοιπά καλλιτεχνικά δρώμενα',
    page: "https://foukou.gitlab.io/makrovoutes/",
  },
  {
    title: "Smash repetition",
    cover: require("assets/img/covers/smash_repetition_cover.png"),
    //short_description: 'Εκθέσεις, φεστιβάλ και λοιπά καλλιτεχνικά δρώμενα',
    page: "https://rastapank.gitlab.io/repetition/",
  },
  {
    title: "notes from a desserted campus",
    cover: require("assets/img/covers/afisadx.png"),
    //short_description: 'Εκθέσεις, φεστιβάλ και λοιπά καλλιτεχνικά δρώμενα',
    page: "https://rastapank.gitlab.io/uocdesert/",
  }
]

class Misc extends Component {
  componentDidMount() {
    dispatchEvent(new Event("load"))
  }

  render() {
    return (
      <DocumentMeta {...get_default_meta({ title: "Culture" })}>
        {window.dispatchEvent(new CustomEvent("new_page"))}
        <div className="main">
          <div className="section text-center">
            <SubsectionsGrid header="Culture" subsections={subsections} />
          </div>
        </div>
        <IndexFooter />
      </DocumentMeta>
    )
  }
}

export default Misc
