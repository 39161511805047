export const photos = [
  {
    src: require("assets/img/events/45.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/44.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/43.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/42.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/41.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/40.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/39.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/38.png"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/37.png"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/36.png"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/35.png"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/34.png"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/33.png"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/32.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/31.png"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/30.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/29.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/28.png"),
    width: 1.4,
    height: 1,
  },
  {
    src: require("assets/img/events/27.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/26.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/25.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/24.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/23.jpg"),
    width: 1.4,
    height: 1,
  },
  {
    src: require("assets/img/events/22.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/21.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/20.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/19.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/18.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/17.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/16.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/15.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/14.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/13.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/11.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/10.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/9.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/8.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/7.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/6.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/5.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/4.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/3.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/2.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/1.jpg"),
    width: 1,
    height: 1.4,
  },
  {
    src: require("assets/img/events/0.jpg"),
    width: 1.4,
    height: 1,
  },
]
