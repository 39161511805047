import React, { Component } from "react"
import IndexFooter from "../components/Footers/IndexFooter"
import { get_default_meta } from "../default_meta"
import DocumentMeta from "react-document-meta"
import { Col, Container, Row } from "reactstrap"

class Chat extends Component {
  componentDidMount() {
    dispatchEvent(new Event("load"))
  }

  render() {
    return (
      <DocumentMeta {...get_default_meta({ title: "Chat" })}>
        {window.dispatchEvent(new CustomEvent("new_page"))}
        <div className="main">
          <div className="section text-center">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="8">
                  <h2 className="title text-left">Chat</h2>
                  <h5 className="description text-left">
                    Tα live chat μας βρίσκονται στα παρακάτω κανάλια.
                    Θα χρειαστεί να εγγραφείτε/συνδεθείτε δίνοντας username/password για
                    να συμμετέχετε στη κουβέντα.
                  </h5>
                </Col>
              </Row>
              <Row className="m-5 justify-content-center">
                <Col xl="3" md="4" sm="6" xs="12">
                  <a href="https://discord.gg/xzxETKtRvJ" >
                  <div className="card" color="blue">
                  <div className="card-header" >

                      <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" fill="currentColor"
                           className="bi bi-discord" viewBox="0 0 16 16">
                        <path
                            d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z"/>
                      </svg>


                  </div>
                  <div className="card-body">
                    <h4 className="card-subtitle mb-2">{"Discord"}</h4>
                  </div>
                  </div>
                  </a>
                </Col>
                  <Col v xl="3" md="4" sm="6" xs="12">
                    <a href="https://t.me/rastapankradio">
                    <div className="card" >
                      <div className="card-header" >

                          <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" fill="#229ED9"
                               className="bi bi-telegram" viewBox="0 0 16 16">
                            <path
                                d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zM8.287 5.906c-.778.324-2.334.994-4.666 2.01-.378.15-.577.298-.595.442-.03.243.275.339.69.47l.175.055c.408.133.958.288 1.243.294.26.006.549-.1.868-.32 2.179-1.471 3.304-2.214 3.374-2.23.05-.012.12-.026.166.016.047.041.042.12.037.141-.03.129-1.227 1.241-1.846 1.817-.193.18-.33.307-.358.336a8.154 8.154 0 0 1-.188.186c-.38.366-.664.64.015 1.088.327.216.589.393.85.571.284.194.568.387.936.629.093.06.183.125.27.187.331.236.63.448.997.414.214-.02.435-.22.547-.82.265-1.417.786-4.486.906-5.751a1.426 1.426 0 0 0-.013-.315.337.337 0 0 0-.114-.217.526.526 0 0 0-.31-.093c-.3.005-.763.166-2.984 1.09z"/>
                          </svg>


                      </div>
                      <div className="card-body">
                        <h4 className="card-subtitle mb-2">{"Telegram"}</h4>
                      </div>
                    </div>
                    </a>

                </Col>
                <Col v xl="3" md="4" sm="6" xs="12">
                  <a href="https://app.element.io/#/room/#rastapank-live:matrix.org">
                    <div className="card" >
                      <div className="card-header" >

                        <svg width="86" height="86" viewBox="0 0 54 54" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M19.4414 3.24C19.4414 1.4506 20.892 0 22.6814 0C34.6108 0 44.2814 9.67065 44.2814 21.6C44.2814 23.3894 42.8308 24.84 41.0414 24.84C39.252 24.84 37.8014 23.3894 37.8014 21.6C37.8014 13.2494 31.032 6.48 22.6814 6.48C20.892 6.48 19.4414 5.0294 19.4414 3.24Z" fill="#0DBD8B"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M34.5586 50.76C34.5586 52.5494 33.108 54 31.3186 54C19.3893 54 9.71861 44.3294 9.71861 32.4C9.71861 30.6106 11.1692 29.16 12.9586 29.16C14.748 29.16 16.1986 30.6106 16.1986 32.4C16.1986 40.7505 22.9681 47.52 31.3186 47.52C33.108 47.52 34.5586 48.9706 34.5586 50.76Z" fill="#0DBD8B"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M3.24 34.5601C1.4506 34.5601 -6.34076e-08 33.1095 -1.41625e-07 31.3201C-6.63074e-07 19.3907 9.67065 9.72007 21.6 9.72007C23.3894 9.72007 24.84 11.1707 24.84 12.9601C24.84 14.7495 23.3894 16.2001 21.6 16.2001C13.2495 16.2001 6.48 22.9695 6.48 31.3201C6.48 33.1095 5.0294 34.5601 3.24 34.5601Z" fill="#0DBD8B"/>
                          <path fill-rule="evenodd" clip-rule="evenodd" d="M50.76 19.4399C52.5494 19.4399 54 20.8905 54 22.6799C54 34.6093 44.3294 44.2799 32.4 44.2799C30.6106 44.2799 29.16 42.8293 29.16 41.0399C29.16 39.2505 30.6106 37.7999 32.4 37.7999C40.7505 37.7999 47.52 31.0305 47.52 22.6799C47.52 20.8905 48.9706 19.4399 50.76 19.4399Z" fill="#0DBD8B"/>
                        </svg>


                      </div>
                      <div className="card-body">
                        <h4 className="card-subtitle mb-2">{"Element"}</h4>
                      </div>
                    </div>
                  </a>

                </Col>
    {/* future card for mailing list  
             <Col v xl="3" md="4" sm="6" xs="12">
                  <a href="//mailing list goes here//"   title="Coming Soon!">
                    <div className="card" >
                      <div className="card-header" >

                        <svg xmlns="http://www.w3.org/2000/svg" width="86" height="86" fill="currentColor"
                             className="bi bi-envelope" viewBox="0 0 16 16">
                          <path
                              d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2-1a1 1 0 0 0-1 1v.217l7 4.2 7-4.2V4a1 1 0 0 0-1-1H2Zm13 2.383-4.708 2.825L15 11.105V5.383Zm-.034 6.876-5.64-3.471L8 9.583l-1.326-.795-5.64 3.47A1 1 0 0 0 2 13h12a1 1 0 0 0 .966-.741ZM1 11.105l4.708-2.897L1 5.383v5.722Z"/>
                        </svg>


                      </div>
                      <div className="card-body">
                        <h4 className="card-subtitle mb-2">{"Mailing List"}</h4>
                      </div>
                    </div>
                  </a>

                </Col>
                */}
              </Row>
            </Container>
          </div>
        </div>
        <IndexFooter />
      </DocumentMeta>
    )
  }
}

export default Chat
