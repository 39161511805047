export const shows = [
  {
    description:
      "100% homemade",
    email: "asmolf@gmail.com",
    facebook: "",
    id: 1,
    instagram: "",
    logo: require("./shows/giagia.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "Σούρντι Σουρντίνας",
      },
    ],
    name: "Gerola Radio",
    scheduled: [],
    short_description: "μουσικοενημερωτική ινστρουμένταλ όπερα",
    twitter: "",
  },
  {
    description:
      "Every other Friday Ben the law spinning tunes and sharing love.",
    email: "",
    facebook: "",
    id: 2,
    logo: require("./shows/ben.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "Ben The Law",
      },
    ],
    name: "Ben & Friends Friday Night selections",
    scheduled: [],
    short_description: "",
  },
  {
    description:
      "Το Teenage Kicks είναι ένα πρόγραμμα ποικίλης μουσικής: rock, μπλουζ, electro, dub, παγκόσμια μουσική, experimental ... ο κατάλογος των μουσικών στυλ δεν είναι εξαντλητικός. Τα ραδιοφωνικά προγράμματα σπάνια βλέπουν αυτά τα τραγούδια και γι 'αυτό η εκπομπή αυτή πρόκειται να τα κάνει να ανακαλύψουν. Οι έφηβοι θα μεταφερθούν πιο κοντά στις συνόδους του Peel του John Peel στο BBC Radio 1 από το 1991 έως το 2004. Μετά από επτά χρόνια στην Αγγλία και 16 χρόνια στη Γαλλία, μετακόμισα στο Ηράκλειο το 2016. Μιλάω άπταιστα Γαλλικά / Αγγλικά / Ρουμανικά και προσπαθώ να βελτιώσω στα ελληνικά. Άρχισα να ακούω μουσική στο ραδιόφωνο με το BBC World Service στα εφηβικά μου χρόνια. Στην Αγγλία και τη Γαλλία, παρακολούθησα εκατοντάδες συναυλίες και ανακάλυψα μια μεγάλη ποικιλία ήχων. Πήγα τακτικά σε μουσικά φεστιβάλ σε όλη τη Δυτική Ευρώπη για να ανακαλύψω νέους ήχους.",
    email: "noroc69@gmail.com",
    facebook: "https://www.facebook.com/djnoroc/",
    id: 3,
    instagram: "",
    logo: require("./shows/dj_noroc.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "Dj Noroc",
      },
    ],
    name: "Teenage Kicks",
    scheduled: [],
    short_description: "",
    twitter: "",
  },
  {
    description:
      'Βουτιά στους ξέφρενους ρυθμούς της swing και στην μεγάλη μουσική παράδοση της τζαζ! Το "the Sunshine Project" έχει βάλει τα δυνατά του για να κάνει τα απογεύματα μας λίγο πιο ηλιόλουστα..',
    email: "",
    facebook: "",
    id: 4,
    instagram: "",
    logo: require("./shows/sunshine_project.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "Stella Maragkaki",
      },
    ],
    name: "The Sunshine Project",
    scheduled: [],
    short_description: "",
    twitter: "",
  },
  {
    description:
      "Στο λινκ μπορείτε να βρείτε το αρχείο του rastatech. https://anchor.fm/rastatech/episodes/RastaTech-17---emqi3s",
    email: "",
    facebook: "",
    id: 5,
    instagram: "",
    logo: require("./shows/rastatech.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "Alex & Greg",
      },
    ],
    name: "Rastatech",
    scheduled: [],
    short_description: "Podcast τεχνολογικής επικαιρότητας",
    twitter: "https://twitter.com/RastaPodcast",
  },
  {
    description: "Τετάρτες 5.30 - 7.30",
    email: "",
    facebook: "",
    id: 6,
    logo: require("./shows/ragazza.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "4rcturian",
      },
    ],
    name: "Ragazza Project",
    scheduled: [],
    short_description: "",
  },
  {
    description: "Μουσικη από την παράδοση της Κρήτης",
    email: "",
    facebook: "",
    id: 7,
    instagram: "",
    logo: require("./shows/strata.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "ggalan",
      },
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "jvalsam",
      },
    ],
    name: "Στης κοντυλιάς τη στράτα...",
    scheduled: [],
    short_description: "Μουσικη από την παράδοση της Κρήτης",
    twitter: " ",
  },
  {
    description:
      "Κάθε Παρασκευή 18:00-20:00: Μαθήματα αυτοάμυνας ενάντια στη σύγχρονη μουσική βιομηχανία. Θεματικές εκπομπές, μόνο μπουμερ επιλογές.",
    email: "",
    facebook: "",
    id: 8,
    logo: require("./shows/selfdefence.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "Νιόκι",
      },
    ],
    name: "SELF DEFENCE AGAINST FRESH FRUIT",
    scheduled: [],
    short_description: "",
  },
  {
    description:
      "live word engineering",
    email: "",
    facebook: "",
    id: 9,
    logo: require("./shows/eleftheravoski.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "sudropa",
      },
    ],
    name: "Ελευθέρα Voskî",
    scheduled: [],
    short_description: "",
  },
 {
    description:
      "Θέμα είναι η ενημέρωση και ευαισθητοποίηση για περιβαλλοντικά θέματα από διάφορες σκοπιές με επίκεντρο την Κρήτη και ένα μεγάλο Όχι αεροδρόμιο στο Καστέλλι.",
    email: "",
    facebook: "",
    id: 10,
    logo: require("./shows/ki_omos_gyrizei.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "",
      },
    ],
    name: "«Κι όμως γυρίζει»",
    scheduled: [],
    short_description: "Εκπομπή από την ομάδα για την προστασία της Πεδιάδας Καστελλίου 'Save the Valley'",
  },
  //--------------------------------------------------------------------------------------

  {
    description: "",
    email: "",
    facebook: "",
    id: 11,
    instagram: "",
    logo: require("./shows/ektakth.jpg"),
    members: [
      {
        avatar: "",
        bio: "",
        id: 0,
        name: "Αρουραίοι",
      },
    ],
    name: "Έκτακτη εκπομπή",
    scheduled: [],
    short_description: "",
  },
]
